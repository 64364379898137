import dynamic from "next/dynamic";
const WebformTradeUpRequest = dynamic(() => import("../webform/webform--trade-up-request").then((mod) => mod.WebformTradeUpRequest), {ssr: false});
const ActonContactForm = dynamic(() => import("../acton/acton--contact-form").then((mod) => mod.ActonContactForm), {ssr: false});
const WebformTechnicalSupportRequest = dynamic(() => import("../webform/webform--technical-support-request").then((mod) => mod.WebformTechnicalSupportRequest), {ssr: false});

export function ParagraphWebform ({paragraph, ...props}) {
  if (!paragraph?.field_webform_form) return <></>

  return (
    <>
      {paragraph?.field_webform_form?.drupal_internal__id === "contact_us" && <ActonContactForm/>}
      {paragraph?.field_webform_form?.drupal_internal__id === "technical_support_request" && <WebformTechnicalSupportRequest webform={paragraph.field_webform_form} />}
      {paragraph?.field_webform_form?.drupal_internal__id === "trade_up_request" && <WebformTradeUpRequest webform={paragraph.field_webform_form} />}
    </>
  )
}
